.mfe-error-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40vh;

  .error-message {
    align-items: center;
    display: flex;
    gap: 1rem;
  }
}