.ocn-points-reward {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(50) rem(55) rem(55);
  margin: rem(13) rem(10) rem(30) rem(10);
  background-color: color('primary-light');
  &__header,
  &__description {
    margin: 2rem 0 1rem;

    hr {
      --color: #666;
  
      border: none;
      color: var(--color);
      height: 0.5px;
      background-color: var(--color);
    }

    p {
      margin: 2rem 0;
    }
  }
  &__create-account {
    width: 100%;
  }
  &__action {
    margin-top: 2rem;
  }
  &__email {
    font-weight: 700;
  }
  &__header .icon-heading[data-variant="large"] div.icon-heading-icon img {
    height: 50px;
    max-height: 50px;
    max-width: 209px;
  }
  @include media-query(700){
    margin-left: auto;
    margin-right: auto;
    max-width: rem(600);
  }
  @include media-query(1025){
    border: 1px solid color("tertiary-light");
    margin-left: auto;
    margin-right: auto;
    max-width: rem(600);
    margin-bottom: rem(40);
  }
}
