.shopping-bag-product-template {
  padding-top: 3rem;
  .price-container {
    .product-price {
      display: flex;
      float: left;

      .product-price-text[data-variant="original"] {
        text-decoration: line-through;
      }
    }
  }

  .product-image-section {
    .product-image-overlay {
      width: 160px;
      max-height: 200px;
    }
  }

  .productcard-header {
    .product-detail {
      display: none;
    }
  }
  .product-price-section {
    margin-top: 0.5rem;
  }
  .product-social-proof-section{
    margin-top: 15px;
  }
  .product-social-proof-section .icon-block[data-orientation='horizontal'] > .icon-block-icon {
    margin-right: 10px;
  }
  .productcard-Image {
    .gift-overlay {
      color: color('primary-dark');
      font-size: 15px;
      left: 10px;
      position: absolute;
      top: 5px;
    }
  
    .gift-overlay-badge {
      top: 25px;
    }
  
  }
  /* inherit styles from parent so it doesn't look like a link */
  .product-name .link-button {
    font-stretch: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: none;
    text-align: left;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
    letter-spacing: inherit;
  }
  .productcard-content {
    gap: unset;
    align-items: stretch;
    .productcard-description {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      .js-pc-sfl-button {
        text-decoration: underline;
        padding: 0;
        font-weight: inherit;
        font-size: 1.3rem;
        color: #5c5a54; /* temporary fix to make a button look like a Link. No color mixin is available for this color code in anf */
        min-height: auto;
        :focus {
          outline: none;
        }
      }
      .product-detail {
        .product-promotions-container {
          .icon-block {
            .icon-block-icon {
              color: text-color('positive');
            }
          }
        }
      }
      .gender {
        margin-top: 5px;
        font-size: 11px;
      }
      .size-color {
        margin-top: 15px;
        font-size: 13px;
      }
      .final-sale-messaging {
        color: text-color('discount');
      }
      .pre-order-shipment-date, .instock-order-delivery-date  {
        padding-top: 15px;
      }
    }
    .productcard-price {
      display: none;
    }
  }
  .productcard[data-variant='bordered']:not(:last-child)  {
    padding-bottom: 15px;
  }
}
.product-image{
  display: block;
}