.gift-card-section {
  .gift-cards-applied-container {
    .gift-cards-max-num-message {
      margin-bottom: 2rem;
    }
    .gift-cards-applied-title {
      margin-bottom: 2rem;
    }
    .gift-cards-applied {
      .payment-block {
        margin-bottom: 2rem;
        border-bottom: 1px solid border-color('container');
        .details {
          width: 100%;
        }
      }
      .gift-card-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .gift-card-action {
          .button {
            &.ds-override {
              font-weight: inherit;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .gift-card-form {
    margin-bottom: 2rem;
    .gift-card-form-container {
      .gift-card-reedeem-title {
        margin-bottom: 2rem;
      }
      .gift-card-apply-up-to-num {
        margin-bottom: 2rem;
      }
      .gift-card-form-wrapper {
        margin-bottom: 2rem;
        .input-field {
          display: flex;
        }
        .tooltip-widget {
          z-index: 99;
          position: static;
          .tooltip-button {
            &::after {
              left: unset;
              right: 2rem;
            }
          }
          .tooltip-content {
            width: 100%;
            img {
              max-width: 15rem;
            }
          }
        }
      }
      .gift-card-action-container {
        margin-bottom: 2rem;
      }
    }
  }
  .gift-card-balance-wrapper {
    margin-bottom: 2rem;
    .gift-card-balance {
      color: text-color('positive');
      font-weight: 700;
      padding: 0;
    }
  }
}