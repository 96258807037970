@import "node_modules/anf-core/dist/1892/1892-framework";

.pay-over-time-story {
  background-color: color("secondary-light");
}

.pay-over-time {
  .horizontal-or-separator {
    margin: 2rem auto;
    text-transform: lowercase;
  }

  .message-placement {
    background-color: color("primary-light");
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px;

    .message {
      text-align: center;
    }

    img {
      height: 10px;
    }

    img.impression {
      display: none;
    }

    .options {
      display: flex;
      align-items: center;
      justify-content: center;

      span, .option {
        align-self: center;
      }

      .or {
        margin-right: 5px;
        text-transform: lowercase;
      }

      button {
        background: none;
        border: none;
        color: unset;
        cursor: pointer;
        font-size: 15px;
        text-decoration: none;

        .icon {
          font-size: 15px;
        }
      }

      .option.klarna, .option.paypal, .or {
        align-items: center;
        display: flex;
      }

      .option.paypal img {
        height: 15px;
      }
    }
  }
}

.pay-over-time-portal.pay-over-time-product {
  margin-bottom: 20px;

  .horizontal-or-separator {
    display: none;
  }

  .message-placement {
    display: inherit;
    padding: 0;

    .message {
      display: inline;
      text-align: left;
      padding-right: 5px;
    }

    .options {
      display: inline-flex;
      padding-left: 1px;
      justify-content: flex-start;
      padding-left: 0;
      padding-bottom: 0;

      .option:first-child {
        padding-left: 1px;
      }
    }
  }
}
