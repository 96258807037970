@include media-query(540) {
  #order-confirmation-1 {
    margin: rem(1) auto 0 auto;
  }
}

@include media-query(1025) {
  #order-confirmation-1 {
    max-width: rem(512);
  }
}

#order-confirmation-1 {
  background-color: #fcfcfa;
  margin-bottom: 50px;

  .recommendation-slider {
    padding: 50px 0;
  }

  [class^=slider-module__slider] {
    margin-bottom: 0;
  }
}