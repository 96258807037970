.app-download-link__container {
  width: 100%;
  
  .app-download-link__wrapper {
    background-color: #f6f6f6;
    padding: 30px;
    margin: 30px 0;
    text-align: center;
  }
  
  .app-download-link__images {
    margin-top: 30px;
    display: flex;

    @media (max-width: 700px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .app-download-link__images a {
    display: block;
    margin: 0 auto;
  }
}
