.marketing-preferences-wrapper {
  @include media-query(0, 700) {
    margin-left: rem(20);
    margin-right: rem(20);
  }

  @include media-query(0, 1025) {
    .email-subscribe-legal-consent {
      margin-bottom: -4rem;
    }
  }

  @include media-query(540) {
    padding-left: rem(35);
    padding-right: rem(35);
  }

  @include media-query(700) {
    padding-left: rem(75);
    padding-right: rem(75);
  }
  .email-subscribe-legal-consent {
    margin-top: 3rem;
  }
}

.marketing-preferences-mfe .marketing-preferences-heading {
  margin-top: rem(20);
}


.marketing-preferences-mfe .checkbox {
  margin-top: rem(30);
}