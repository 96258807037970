#pickup-search-container {
  font-family: DS Trade Gothic, Trade Gothic, sans-serif;
  padding: 36px 0px 12px 0px;
  line-height: 1.5384615385;
  color: #666;
  -webkit-font-smoothing: antialiased;

  .pickup-search-title {
    margin: 0 0 3rem 0;
    font-weight: 700;
    color: #000;
  }

  .pickup-search-country {
    margin-bottom: 30px;
  }

  .pickup-search-input {
    width: 100%;
  }

  .pickup-search-button {
    max-width: 60px;
    padding-right: 5px;
  }
  
  .button[data-variant="secondary"] > [data-icon] {
    margin: 0;
  }

  .button[data-variant="secondary"] > [data-icon="search"] {
    max-width: 60px;
    padding-right: 7px;
  }

  .nearme-button {
    height: 100%;
    padding: 0px 20px;
    min-width: 140px;
    min-height: 60px;
  }

  .nearme-button-text {
    margin-left: 8px;
  }

  .horizontal-separator-wrapper {
    text-align: center;
    font-size: 1.3rem;
    letter-spacing: 0.6px;
    padding: 20px 0px;
  }
}