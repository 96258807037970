#gift-box-modal {
  .modal-heading {
    min-height: 0;
    padding: 0;
  }

  .modal-content {
    padding: 0;

    .giftbox-image-header {
      .giftbox-bg-image {
        $giftbox_images_by_brand: (
          anf: "https://img.abercrombie.com/is/image/anf/A-20180906-M-giftbox-modal.jpg",
          kids: "https://img.abercrombie.com/is/image/anf/A-20180906-M-giftbox-modal.jpg",
          hol: "https://img.hollisterco.com/is/image/anf/hco-20181221-gift-box-modal-mobile.png",
          gh: "https://img.hollisterco.com/is/image/anf/hco-20181221-gift-box-modal-mobile.png",
          st: "https://img.hollisterco.com/is/image/anf/hco-20181221-gift-box-modal-mobile.png",
        );

        background-image: url(map-get($giftbox_images_by_brand, $brand));
        background-size: 100% 100%;
        border-radius: 0;
        height: 225px;
        position: absolute;
        top: 0;
        width: 100%;

        @include media-query(540) {
          $giftbox_images_by_brand: (
            anf: "https://img.abercrombie.com/is/image/anf/A-20180906-D-giftbox-modal.jpg",
            kids: "https://img.abercrombie.com/is/image/anf/A-20180906-D-giftbox-modal.jpg",
            hol: "https://img.hollisterco.com/is/image/anf/hco-20181221-gift-box-modal-desktop.png",
            gh: "https://img.hollisterco.com/is/image/anf/hco-20181221-gift-box-modal-desktop.png",
            st: "https://img.hollisterco.com/is/image/anf/hco-20181221-gift-box-modal-desktop.png",
          );

          background-image: url(map-get($giftbox_images_by_brand, $brand));
          height: 300px;
        }
      }

      .gift-box-data {
        margin-top: 165px;
        padding: 15px 15px 45px;
        position: relative;

        @include media-query(540) {
          margin-top: 0;
          padding: 40px;
        }

        .data-card {
          &.giftbox-modal {
            box-shadow: 0 2px 20px 0 color("secondary-light");
            padding: 20px;
            width: 100%;

            @include media-query(540) {
              padding: 40px;
              width: 400px;
            }

            .data-card-content {
              h1 {
                color: #253746;
                font-family: garamond-premier-pro, serif;
                font-size: 2.8rem;
                font-stretch: 100%;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 1.2857142857;
              }

              hr {
                margin: 30px 0;
              }
            }
          }
        }

        #giftbox-form {
          margin-top: 50px;

          .product-template.giftbox {
            margin-bottom: 2rem;
            width: 100%;

            .product-image-section {
              .product-image {
                padding-right: 2rem;

                @include media-query(540) {
                  padding-right: 2rem;
                }

                img {
                  height: 100px;
                  width: 80px;

                  @include media-query(540) {
                    height: auto;
                    width: auto;
                  }
                }
              }
            }

            .product-content {
              .product-content {
                .add-a-giftbox {
                  margin: 2rem 0;
                }
              }

              .product-footer {
                border-top: 1px solid color("secondary-light");
                flex: 1 0 100%;
              }
            }

            .product-secondary-content {
              .gift-messages {
                float: right;
                max-width: 100%;
                width: 40rem;
              }
            }
          }

          .giftbox-actions {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include media-query(540) {
              flex-direction: row;
              justify-content: flex-start;
            }

            .button.gift-box-save-button {
              @include media-query(540) {
                width: 26rem;
              }
            }

            .button.gift-box-cancel-button {
              margin-top: 2rem;

              @include media-query(540) {
                margin-left: 4rem;
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }

  .modal-close-button {
    background-color: color("primary-light");
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
  }

  .modal-block {
    width: 100%;

    @include media-query(1025) {
      max-width: 900px;
    }
  }
}

//for Choose Free Gift Modal
.scope-1892.gift-with-purchase{
  .product-template.gwp-product-template{
    margin-bottom: 20px;
    .product-content{
      padding-left: 15px;
    }
    .product-name{
      margin-bottom: 20px;
    }
    .product-price.gwp-product-price{
      display: inline-block;
      margin-bottom: 10px;
    }
  }
}
