@import 'node_modules/anf-core/dist/1892/1892-framework';

.take-survey{
  background-color: color("primary-light");
  margin: rem(10) rem(10) rem(30) rem(10);
  padding: rem(30) rem(20) rem(40);
  text-align: center;

  .survey-description {
    margin-bottom: 2rem;
  }

  .survey-heading:first-child {
    margin-bottom: rem(7);
  }

  .survey-submit-btn {
    max-width: none;
  }

  @include media-query(700) {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
    max-width: rem(600);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(30);
  }
  
  @include media-query(1025){
      max-width: rem(600);
      padding: rem(30) rem(40) rem(40) rem(40);
      background-color: color("secondary-light");
      margin: 1rem auto 4rem auto;
    }
}
