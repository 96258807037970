.first-row {
  display: flex;
}

.store-name {
  color: color('primary-dark');
}

.info-window-location-name {
  display: flex;
  font-weight: 700;
  margin-left: 10px;
}

.secondary-row {
  margin-left: 30px;
}

.select-store-button {
  margin-top: 15px;
}

.day-of-week {
  display: block;
  margin-right: 10px;
}

.info-window-disclosure-wrapper {
  margin-top: 10px;
}

.info-window {
  position: absolute;
  width: 100%;
  padding: 10px;
  top: 0px;
  left: 0px;
}

.info-window-close-icon {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 15px;
  top: 15px;
}

.info-window-store-hours {
  margin-left: -10px;
}
