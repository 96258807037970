@import 'node_modules/anf-core/dist/1892/1892-framework';

#miniBag-toaster {
    opacity: 0;
    z-index: 1002;

    .pipe-delimited-links .pipe-delimited-link {
        margin-right: 5px;
    }

    .modal-overflow-wrapper {
        max-width: 450px;
        position: absolute;
        right: 0;
        width: 450px;
    }
    a:focus {
        outline: none;
    }

    .modal-block {
        height: 100vh;
        overflow-y: unset;
    }

    .scope-1892 .button-group[data-variant='grid'] {
        flex-wrap: nowrap;
        &::before {
            flex-basis: 0;
        }
    }

    .shopping-bag-list-mfe {
        .product-card,
        .product-template {
            display: block;
            .gift-overlay {
                position: absolute;
                top: 5px;
                left: 10px;
                color: color('primary-dark');
                font-size: 15px;
            }
            .productcard-content {
                gap: unset;
                .productcard-header {
                    .product-detail {
                        display: none;
                    }
                }
                .productcard-description {
                    .product-detail {
                        .gender {
                            margin-top: 5px;
                            font-size: 11px;
                          }
                          .size-color {
                            margin-top: 15px;
                            font-size: 13px;
                          }
                    }
                }
            }
            .productcard-price {
                display: none;
            }
            .product-header > .badge,
            .product-footer > .badge {
                background-color: color('primary-dark');
                color: color('primary-light');
                font-size: .9rem;
                width: 100px;
            }
        }
    }
    .scope-1892 .icon-button[data-theme="inverse"]:not(:disabled):hover {
        cursor: pointer;
    }

    .mini-bag-ctas {
        margin: 20px 30px;
    }

    .modal-content {
        position: fixed;
        top: 60px;
        bottom:  var(--mini-bag-bottom-height);
        overflow-y: auto;
        padding: 0 30px;
        height: auto;
        width: 450px;
    }
    .modal-heading {
        padding: 0 20px;
        z-index: 1001;
    }

    .modal .modal-block {
        min-height: 100vh;
        width: 450px;
    }

    .empty-bag {
        border-bottom: 1px solid;
        border-bottom: 1px solid color('tertiary-light');
        padding: 40px 10px 30px;
        text-align: center;
    }

    .modal[data-sticky-header='true'] .modal-content {
        padding-top: 75px;
    }

    .toaster-footer {
        background-color: color('secondary-light');
        bottom: 0;
        position: fixed;
        text-align: center;
        width: 450px;
    }

    .free-shipping-container {
        margin: 20px 50px;

        .free-shipping-text {
            text-align: center;
        }
    }
    .button-group-container {
        padding: 10px 20px;
    }

    .order-total-summary {
        border-bottom: 1px solid color('tertiary-light');
        display: flex;
        justify-content: space-between;
        margin: 30px 30px 0 30px;
        padding-bottom: 20px;
    }
    .list-section {
        overflow-x: hidden;
        &.notification {
            margin-top: 30px;
        }
    }
    .product-list-height {
        list-style: none;
        max-height: 75%;
        position: relative;
    }
    
    .modal[data-sticky-header='true'] .modal-content {
        padding-top: 90px;
    }

    .shopping-bag-list-mfe .product-card {
        margin-top: 20px;
    }

    .product-template > .product-image-container {
        margin: 20px;
        width: 100px;
        .product-header {
            flex: 1 0 100%;
        }
    }

    .product-card .pre-order-badge,
    .product-template .pre-order-badge {
        padding: 0;
    }

    .product-price .clearance-badge {
        color: color('error');
        font-weight: normal;
    }

    .product-price > .product-price-text-wrapper > .product-price-text {
        display: inline-block;
        margin-right: 7px;
    }

    .product-image {
        border: 1px solid color('tertiary-light');
    }
    .product-template-item {
        margin-top: 20px;
    }

    .product-image > img {
        display: block;
        width: 100%;
        margin: auto;
    }

    .product-image-overlay {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .edit-button,
    .js-pc-edit-button {
        border: none;
        cursor: pointer;
        justify-content: flex-start;
        padding-left: 0;
    }

    .edit-button:focus,
    .remove-button:focus,
    .js-pc-edit-button:focus,
    .js-pc-remove-button:focus {
        outline: none;
    }

    .remove-button,
    .js-pc-remove-button {
        border: none;
        width: 50%;
    }

    .button[data-variant=tertiary-light],
    .button[data-variant='tertiary-button'] {
        background-color: transparent;
        color: color('tertiary-dark');
        text-decoration: underline;
        text-transform: capitalize;
    }

    .progress-bar {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    progress::-moz-progress-bar {
        background: color('accent');
    }

    progress::-webkit-progress-value {
        background: color('accent');
    }

    progress {
        color: color('accent');
    }

    .progress-bar .progress-bar-prelabel {
        margin-right: 10px;
    }

    .progress-bar progress {
        background: color('secondary-light');
        border-radius: 20px;
        border-style: none;
        height: 5px;
        width: 100%;
    }

    .progress-bar .progress-bar-postlabel {
        margin-left: 10px;
    }

    .modal-heading > .modal-close-button {
        border-radius: 50%;
        position: inherit;
        right: 10px;
        top: 10px;
        z-index: 1;
    }

    .modal-heading > .modal-close-button {
        border-radius: 50%;
        position: inherit;
        right: 10px;
        top: 10px;
        z-index: 1;
    }

    .modal-close-button {
        background-color: color('secondary-light');
    }

    .modal .modal-close-button {
        background: none;
        border: 0;
        cursor: pointer;
    }
    .modal-close-icon::before {
        font-size: 2rem;
        height: 40px;
        padding: 10px;
        width: 40px;
    }

    .modal .modal-close-icon::before {
        color: color('tertiary-dark');
        content: '';
        display: block;
        font-family: Responsive Icons;
        height: 45px;
        width: 45px;
    }

    .image-width {
        width: 100px;
    }
    .product-social-proof-section{
        margin-top: 10px;
    }
    .product-social-proof-section .icon-block[data-orientation='horizontal'] > .icon-block-icon {
        margin-right: 10px;
    }
    .modal-heading .notification-container {
        padding: 0px;
        margin-left: -20px !important;
    }
    .notification-container {
        position: absolute;
        width: 450px;
        padding: 0px !important;
        margin-left: 0px !important;
        margin-top: 20px;
    }
    .notification-component {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: color('primary-dark');
        color: #ffffff; 
        padding: 5px 10px;
        margin: 0 auto;
        font-family: inherit;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        width: 100%;
        height: 40px;
        z-index: 1001;
      }
}
