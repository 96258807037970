.order-contact-wrapper {
  @include media-query(0, 700) {
    margin-left: rem(20);
    margin-right: rem(20);
  }

  @include media-query(540) {
    padding-left: rem(35);
    padding-right: rem(35);
  }

  @include media-query(700) {
    padding-left: rem(75);
    padding-right: rem(75);
  }
}

.order-contact-info-mfe {
  .heading-banner {
    margin-bottom: rem(20);
  }
  .sms-section {
    margin-top: rem(20);
    .legal-text{
      margin-top: rem(20);
    }
  }
  
}