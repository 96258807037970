.checkout-mfe-web-service {
  .bag-copy-mobile {
    display: inline-flex;
    @include media-query(700) {
      display: none;
    }
  }
 
  .bag-copy-desktop {
    display: none;
    @include media-query(700) {
      display: inline-flex;
    }
  }
}

.mobile-mini-bag {
  cursor: pointer;
}
#checkout-bag-summary {
  .disclosure-label {
    margin-right: 0;
    margin-left: 40px;
  }
  .shopping-bag-content {
    .sticky-bag-summary-baglist {
      ul, li {
        list-style-type: none;
      }
      .productcard {
        width: 100%;
      }
      .product-card-ctas {
        display: none;
      }
    }
  }
}
