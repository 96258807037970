.shipping-speed {
  padding: rem(20) 0;

  &.updating {
    opacity: 0.5;
  }
  
  .radio-button-group {
    legend span {
      @include heading-text(2);
    }
    
    fieldset:not([data-horizontal="true"]) .radio-button {
      border-bottom: none;
    }
  }
}

.sdd.shipping-speed-option {
  .radio-button input:disabled+label {
    opacity: 1;
    
    span.custom-radio {
      opacity: .5;
    } 

    > span.radio-button-interior-label > span:first-child {
      opacity: .5;
    }

    span.secondary {
      opacity: 1;
    }

    .sdd-info > span > span:first-child,
    .sdd-info > span > span:first-child + span {
      opacity: .5;
    }

    .change-zip, .change-zip > a {
      opacity: 1;
    }
  }

  span.order-by-time {
    display: block;
  }
}

.sdd-story-controls {
  border-top: thin solid #CCC;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 1em;
  padding-top: 1em;
}