.order-confirm-wrapper {
  @include media-query(540){
    margin-bottom: 55px;
  }
  @include media-query(825){
    margin-bottom: 77px;
  }
  @include media-query(1025){
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    background-color: color('primary-light');
  }
  @include media-query(1500){
    margin: 11rem auto 142px auto;
    max-width: 1300px;
    display: flex;
    background-color: color('primary-light');
  }

  .ocn-left-rail {
    padding-top: 7rem;
    margin-left: auto;
    margin-right: auto;
    @include media-query(1025){
      width: 662px;
    }
    .core-container {
      @include media-query(700){
        max-width: rem(600);
        margin-right: auto;
        margin-left: auto;
      }
      @include media-query(1025){
        max-width: rem(600);
      }
    }
    .small-screen-order-summary {
      border-top: 1px solid color('tertiary-light');
      display: block;
      margin-top: rem(20);

      .header-bag-text {
        display: none;
      }
      .product-template[data-status='Sold Out'] {
        background-color: unset;
      }
      
      .product-template-item {
        margin-top: 3rem;
        .shopping-bag-product-template {
          padding-top: 0;
        }
      }
      
      .order-summary {
        background-color: color('primary-light');
        padding-top: 0;
        @include media-query(700) {
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 0 ;
        }
      }
      h3 {
        margin: 3rem 0 2rem 0;
      }
      @include media-query(1025) {
        display: none;
      }
    }
    .footer-content {
      padding: 0 rem(30) rem(50) rem(30);
      @include media-query(1025){
        padding: 0 0 rem(40) 0 ;
      }
      .cust-service {        
        @include media-query(700){
          margin: 0;
        }

        @include media-query(1025){
          margin: 0 rem(40);
        }
      }
    }
  }
  .ocn-right-rail {
    background-color: color('secondary-light');
    display: none;
    float: right;
    padding: 5rem;
    width: 34%;
    @include media-query(1025){
      display: block;
    }
    .right-rail-wrapper {
      margin-left: auto;
      margin-right: auto;
      width: 85%;
    }
  }
  .small-screen-selector {
    display: block;
    @include media-query(1025){
      display: none;
    }
  }
}

.loader-area {
  max-width: 1300px;
  margin: 11rem auto;
}
