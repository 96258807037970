.ship-or-pickup-header-title {
  .heading-banner {
    justify-content: space-between;
    margin-bottom: 4rem;
  }

  .headline {
    padding-right: 20px;
  }

  .country-section {
    margin-top: 1rem;
  }

  .country-section {
    display: flex;
  }
}