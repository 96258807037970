.franchise-country-repudiation-container {
  margin: 30px 10px;

  @include media-query(1025) {
    margin: 0;
  }

}

.franchise-country-redirect-notice {
  margin-top: 16px;
  span {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.08px;
  }
}

.franchise-country-items-not-saved-notice {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 13px;
  margin-bottom: 12px;
  .message {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.06px;
  }
  }