.button[data-variant='tertiary-light'] {
  width: 100%;
}

.shopping-bag-gift-box {
  .gift-box-line-items {
    margin: 2rem 0;
    .gift-box-line-item {
      display: flex;
      justify-content: space-between;
    }
  }
}