.order-in-store-ctas {
  max-width: 35rem;
  
  .send-to-register-button,
  .send-to-customer-button,
  .horizontal-or-separator {
    margin-bottom: 2rem;
  }
  .send-to-customer-button {
    display: none;
  }
}

.ois-modal-form {
  p, 
  .input-field, 
  .checkbox,
  .error-message {
    margin-bottom: 2rem;
  }
  button + button {
    margin-top: 2rem;
  }
}