.order-confirmation-hero-container {
  text-align: center; 
  margin-top: 1rem;
  margin-bottom: 4rem;
  .order-confirmation-mfe-card {
    @include media-query(540){
      margin: 1rem 0 0;
    }
    @include media-query(1025){
      margin-left: auto;
      margin-right: auto;
      max-width: rem(600);
    }
    span {
      &:first-child {
        font-weight: 700;
        padding-right: 0.5rem
      }
    }
  }

}
