.product-template-item {
  list-style-type: none; 
  .product-detail-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .productcard-Image {
    line-height: 1;
  }
  .product-card-ctas {
    padding-top: 2rem;
    ul {
      display: inline-table;
      list-style-type: none;
      @include media-query(1025) {
        margin-top: 1rem;
      }
      li {
        display: inline-block;
        margin-right: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
}