.minibag-with-icon-comp-desktop,
.minibag-with-icon-comp-mobile {
  .minibag-nav-largescreen-legacy {
    margin-top: 20px;
  }

  .minibag-nav-largescreen {
    background-color: transparent;
    border: none;
    position: relative;
    display: block;
    font-family: inherit;
    min-width: 50px;
    text-align: center;
    width: 100%;
    color: color("primary-dark");
    font-size: 1.7rem;
    :hover {
      cursor: pointer;
    }
    .item-count-badge {
      left: 25px;
      top: 0;
    }
  }

  .mini-bag-a11y-desc {
    display: none;
  }

  .minibag-under-icon-block {
    opacity: 0;
  }

  .minibag-nav-smallscreen-legacy {
    position: absolute;
    right: 10px;
    /* This minibag icon at the required position. */
    /* Hence, the values are not in multiples of 5. */
    top: 13px;
  }

  .minibag-nav-smallscreen-mfe {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
  }

  .minibag-nav-smallscreen {
    color: color("primary-dark");
    background: transparent;
    border: none;
    height: 40px;
    line-height: 40px;
    width: 35px;
    @include media-query(1025) {
      text-decoration: none;
    }

    .item-count-badge {
      /* This positions the items-in-bag span above the minibag icon at the required position. */
      /* Hence, the values are not in multiples of 5. */
      top: 0;
      right: -3px;
    }
  }

  .item-count-badge {
    align-items: center;
    background-color: color("error");
    border: 1px solid transparent;
    border-radius: 10px;
    color: color("neutral-000");
    display: none;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: inherit;
    height: 20px;
    justify-content: center;
    letter-spacing: 0;
    position: absolute;
    width: 20px;
  }

  .has-count {
    display: flex;
  }

  .legacy-mobile-minibag {
    display: none;
  }
}
