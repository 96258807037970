.payment-rail {
  padding: 5rem 2rem;
  @include media-query(1025) {
    padding: 3rem 2rem;
  }
  .rail-flex-box {
    .shopping-bag-ctas {
      margin-bottom: 5rem;
      .horizontal-or-separator {
        margin: 2rem 0;
        text-transform: lowercase;
      }
    }
    .promotion-desktop {
      display: block;
      @include media-query(0, 1025) {
        .single-entry-form .input-field {
          width: 100%;
        }
      }
    }
    .shopping-bag-gift-box {
      .accordion[data-type="boxed"] .accordion-trigger {
        background-color: color('secondary-light');
        padding-left: 0;
      }
      .accordion[data-type="boxed"]:not([data-expanded-variant="true"]) .accordion-panel {
        background-color: color('secondary-light');
      }
      .accordion[data-type='boxed'] .accordion-icon {
        right: 0;
      }
    }
    .disclosure-info .accordion {
      padding-top: 3rem;
    }
    @include media-query(1025) {
      margin: 0 auto;
      width: 65%;
    }
  }
  @include media-query(700) {
    margin-left: auto;
    margin-right: auto;
    max-width: 589px;
  }
  @include media-query(1025) {
    display: block;
    float: right;
    margin-top: 5rem;
    padding: 0;
    width: 34%;
  }
}