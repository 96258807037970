.checkout-empty-bag {
  max-width: 360px;
  margin: auto;
  
  .emptybag-signin__content {
    text-align: center;
  }

  .emptybag-signin-header-text, 
  .genericESpot {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .h4 {
    font-weight: 500;
  }

  button {
    width: 100%;
  }

  .emptybag-shopbuttons__content {
    margin-top: 40px;
    border-top: 1px solid #CCCCCC;
  }

  .empty-bag-buttons__button-wrap--anf,
  .empty-bag-buttons__button-wrap--kids,
  .empty-bag-buttons__button-wrap--hol,
  .empty-bag-buttons__button-wrap--gh,
  .empty-bag-buttons__button-wrap--st {
    display: none;
  }
}

