.how-it-works {
  padding: 0 20px;
  @include media-query(1025){
    padding: 0 40px;
  }
  .heading-banner {
    align-items: center;
    font-family: inherit;
    font-stretch: 100%;
    text-transform: none;
    min-height: 60px;
    border-bottom: none;
    padding: 12px 0;
  }
  .icon-block-wrapper {
    display: flex;
    padding-bottom: 20px;
    .icon-text {
      margin-left: 1rem;
    }
  }
}