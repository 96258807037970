.needHelp-content {
  padding: 20px 0px 0px;
  .button.ds-override.button-as-link.js-shipping-and-handling-modal-open {
    min-height: 50px;
  }
  .ship-and-hand .genericESpot {
    border-bottom: 1px solid color('tertiary-light');
    margin-bottom: 1rem;
    padding-bottom: 2rem;
  }
  .button[data-variant=borderless]{
    font-weight: 400;
    color: color('body-text-color');
    padding-left: 0;
  }
  .vat-details-button {
    text-transform: capitalize;
    text-decoration: underline;
  }
  ul {
    list-style: none;
  }
  .links .icon-block {
    display: flex;
  }
  .links .icon-block-icon {
    margin-right: 10px;
  }
}
