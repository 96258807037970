.repudiation-section {
  background-color: color('secondary-light');
  .bag-repudiation-message {
    .bag-repudiation-error-desc {
      margin: 2rem 0;
    }
  }
  .bag-repudiation-error {
    padding: 0 2rem 3rem 2rem;
    width: 100%;
    background-color: color('secondary-light');
    @include media-query(1025){
      
    }
    .repudiation-error {
      .repudiation-itemlist-wrap {
        border-bottom: 2px solid color('error');
        margin-bottom: 2rem;
        margin-top: 3rem;
        .shopping-bag-list-mfe {
          background-color: color('secondary-light');
          li {
            list-style: none;
            padding-top: 2rem;
            background-color: color('secondary-light');
            &:first-child{
              padding-top: 0;
            }
          }
          .product-template-item {
            &:last-child {
              border-bottom: 0;
            }
            .shopping-bag-product-template {
              background-color: color('secondary-light');
              padding-top: 0;
            }
            .product-template {
              .productcard {
                .productcard-badge {
                  display: none;
                }
                .productcard-content {
                  .productcard-header {
                    .close {
                      display: none;
                    }
                  }
                  .productcard-description {
                    .product-detail {
                      p:first-child {
                        display: none;
                      }
                      .product-promotions-container {
                        display: none;
                      }
                    }
                    .pipe-delimited-links {
                      display: none;
                    }
                  }
                  .productcard-price {
                    margin-top: 0;
                    .product-price, .product-price-text-wrapper {
                      justify-content:flex-start;
                    }
                  }
                }
              }
            }
          }
        }
      }
      
      .error-button-container {
        .continue-to-checkout, .continue-in-currency {
          margin-top: 2rem;
        }
        .continue-to-checkout{
          padding: 0;
        }
      }
    }
  }
}