.footer-content {
  padding: 50px 0px;
  @media (min-width: 1025px){
    padding-left: 15px;
  }
  .cust-service {
    @media (min-width: 1025px){
      padding-bottom: 100px;
    }
    margin-bottom: 3rem;
    margin-top: 1.25rem;
    .cust-service-phone-number-block, .order-number-block {
      margin-top: 20px;
      .or {
        display: inline;
        margin: 0px 1rem;
        text-transform: lowercase;
      }
    }
  }
  .cust-service-phone-number-block .icon-block[data-orientation='horizontal']{
    align-items: flex-start;
  }

  [data-icon='phone-contact']::before,
  [data-icon='content-anf']::before {
    height: 2rem;
    font-size: 2rem;
    width: 2rem;
  }

  ul {
    list-style: none;
  }
  @media (min-width: 1025px){
    .cust-service {
      margin: 0 auto;
      max-width: 30rem;
      min-width: 30rem;
      width: 75%;
    }
  }
}
