#verifyAddress-modal {
  .modal-close-button {
    display: none;
  }

  .loader-verification-screen {
    height: 25px;
    margin: 25px auto;
    width: 25px;
  }

  .entered-poor-address {
    padding-bottom: 10px;
  }

  .suggested-address {
    display: flex;
    flex-direction: column;
  }

  .highlight {
    font-weight: bold;
  }
}