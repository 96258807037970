#pudos-modal {

  .pudos-stores-list {
    list-style: none;
  }

  .pudos-search-wrapper {
    // margin: 0px 0px 0px 0px;
    padding-bottom: 30px;
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  .nearme-button {
    height: 100%;
    padding: 0px 20px;
    min-width: 130px;
  }

  .pudos-search-input {
    width: 100%;
  }

  .pudos-search-button {
    max-width: 70px;
    padding-right: 5px;
  }
  
  .button[data-variant="secondary"] > [data-icon] {
    margin-right: 0px;
  }

  .button[data-variant="secondary"] > [data-icon="search"] {
    max-width: 70px;
    padding-right: 7px;
  }

  .nearme-button-text {
    margin-left: 5px;
  }

  .pudos-modal-hr {
    border-style: none;
    border-top-style: none;
    border-top: 1px solid #ccc;
  }

  .pudos-modal-hr:first-of-type {
    // margin: 40px 0px 25px;
  }

  .pudos-modal-hr:last-of-type {
    margin: 10px 0px;
  }

  .pudos-store-card-wrapper {
    padding-bottom: 20px;
    .store-card-left-container {
      .store-card-logo-container {
        padding-bottom: 5px;
        .fedex_onsite, .fedex_office {
          width: 100px;
        }
      }
    }
  }

  .store-card-content {
    width: 100%;
  }

  .first-row {
    display: flex;
  }

  .store-name {
    color: color('primary-dark');
  }

  .pudos-location-name {
    display: flex;
    font-weight: 700;
    margin-left: 10px;
  }

  .secondary-row {
    margin-left: 30px;
  }

  .horizontal-separator-wrapper {
    margin: 15px 0px;
  }

  .select-store-button {
    margin-top: 15px;
  }

  .no-stores {
    margin-top: 25px;
    color: color('error');
    margin-bottom: 25px;
  }

  .pudos-error {
    margin-bottom: 25px;
  }

  .load-more-button {
    display: block;
    padding-bottom: 10px;
    padding-top: 15px;
    text-align: center;
  }

  .day-of-week {
    display: block;
    margin-right: 10px;
  }

  .pudos-disclosure-wrapper {
    margin-top: 10px;
  }

  .pudos-view-controls {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    // margin-bottom: 25px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .locations-near-text {
    display: flex;
    align-items: center;
  }

  .pudos-toggle {
    display: block;
    align-items: center;
    max-width: 300px;
    // width: 17vw;
    width: 29vw;
  }

  .pudos-map-container {
    position: relative;
    overflow: hidden;
  }

  // 700 px and smaller
  // Band-aid fix for DS StoreCard styles
  @media only screen and (max-width: 700px) {
    .pudos-view-controls {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      // margin-bottom: 25px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .pudos-toggle {
      display: block;
      align-items: center;
      margin-top: 10px;
      width: 100%;
    }

    .pudos-store-card-wrapper {
      .store-card-jsx{
        flex-direction: column;
  
        .store-card-left-container {
          width: 100%;
        }
        .store-card-right-container {
          flex-direction: row;
          width: 100%;
        }
      }
    }
  }

}
