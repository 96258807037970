.save-for-later {
  &.sfl-stacked {
    margin-top: 5rem;
    .sfl-description {
      margin-bottom: 5rem;
      margin-top: 0;
    }
  }
  .sfl-description {
    margin-bottom: 3rem;
    margin-top: 3rem;
    padding: 0 2rem;
    @include media-query(1025) {
      padding: 0;
    }
  }
  .error-section {
    margin-bottom: 2rem;
  }
  .saved-for-later-product-list {
    .empty-sfl {
      margin-top: 3rem;
      margin-bottom: 5rem;
      padding: 0 2rem;
      @include media-query(1025) {
        margin-bottom: 1rem;
        padding: 0;
      }
    }
    li {
      list-style: none;
    }
    .productcard {
      margin-top: 3rem; // Invision has gap between product cards. DS doesn't bring any padding/margin with product cards
      width: 100%;
      .productcard-content {
        gap: unset;
        align-items: stretch;
        .productcard-price {
          display: none;
        }
        .productcard-header {
          .product-detail {
            display: none;
          }
        }
        .productcard-description {
          display: flex;
          flex-direction: column;
          gap: 10px;
          flex: 80%;
          justify-content: space-between;
          
            .gender {
              margin-top: 5px;
              font-size: 11px;
            }
            .size-color {
              margin-top: 15px;
              font-size: 13px;
            }
            .product-price-section {
              margin-top: 0.5rem;
            }
          
          .move-to-bag {
            text-decoration: underline;
            padding: 0;
            font-weight: inherit;
            min-height: auto;
            font-size: 1.3rem;
            color: #5c5a54; /* temporary fix to make a button look like a Link. No color mixin is available for this color code in anf */
            :focus {
              outline: none;
            }
          }
        }
      }
    }
  }
  .load-more, .load-less {
    padding: 2rem 2rem 0;
    @include media-query(1025){
      padding: 2rem 0 0;
      max-width: 15rem;
    }
  }
}