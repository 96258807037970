@import "node_modules/anf-core/dist/1892/1892-framework";

$checkout-vertical-spacing-medium: 30;

#mfe-sdd-modal {
  .findme-wrapper {
    button {
      height: 100%;
    }
  }
  .same-day-delivery-bottom {
    p {
      border-top: thin solid color("container");
      padding-top: 20px;
    }
  }
  .sdd-search-horizontal-wrapper,
  .sdd-search-vertical-wrapper {
    margin-bottom: rem(40);
    margin-top: rem(20);
  }

  .sdd-search-horizontal-wrapper {
    @include media-query(700) {
      display: flex;
    }

    display: none;

    .search-form-wrapper {
      flex-grow: 1;
    }
  }

  .sdd-search-vertical-wrapper {
    @include media-query(700) {
      display: none;
    }

    @include media-query(540) {
      padding-left: 0;
      padding-right: 0;
    }

    display: block;

    .horizontal-or-separator {
      padding: 30px 0;
    }

    .search-form-wrapper {
      display: block;
    }
  }

  .vertical-separator {
    flex: 1;
  }
}

.modal-content {
  .sdd-search-horizontal-wrapper,
  .sdd-search-vertical-wrapper {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}
