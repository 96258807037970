/*
 * animate with 'react-transition-group'
 */

// animation styles for fade-in effect
.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 1000ms ease-out;
}

// animation styles for slide effect
// slide left: currently used for removing an item from cart
.slide-left-enter {
    opacity: 0;
    transform: translateX(100%);
}
.slide-left-enter-active {
    opacity: 1;
    transform: translateX(0%);
}
.slide-left-exit {
    opacity: 1;
    transform: translateX(0%);
}
.slide-left-exit-active {
    opacity: 0;
    transform: translateX(-100%);
}
.slide-left-enter-active,
.slide-left-exit-active {
    transition: opacity 500ms, transform 500ms;
}

// slide right: currently used for removing an item from cart
.slide-right-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.slide-right-enter-active {
    opacity: 1;
    transform: translateX(0%);
}
.slide-right-exit {
    opacity: 1;
    transform: translateX(0%);
}
.slide-right-exit-active {
    opacity: 0;
    transform: translateX(100%);
}
.slide-right-enter-active,
.slide-right-exit-active {
    transition: opacity 500ms, transform 500ms;
}
