.sms-optin {
  background-color: color("primary-light");
  margin: rem(10) rem(10) rem(30) rem(10);
  padding: rem(30) rem(20) rem(40);
  text-align: center;

  @include media-query(700) {
    margin-left: 0;
    margin-right: 0;
  }
  @include media-query(1025) {
    background-color: color("secondary-light");
    margin-left: 0;
    margin-right: 0;
    max-width: rem(600); // Adding this for Off state SMS opt-in
  }

  .text-details {
    padding-top: 10px;
    text-align: left;
  }
}