.shopping-bag-promotion-box {
  @include media-query(1025) {
    .accordion[data-type='boxed'] .accordion-icon {
      right: 0;
    }
  }
  .single-entry-form {
    display: flex;
    padding-bottom: 1rem;
    .input-field>input, [data-single-entry="true"]>input {
      max-width: 100%;
    }
  }
  div[data-display='false'] {
    display:none;
  }
  .discount-card {
    margin-top: 10px;
  }
  .button[data-variant=secondary] {
    margin-top: 0;
  }
  .applyPromo{
    min-width: auto;
    @include media-query(1025) {
      min-width: unset;
    }
  }
}