.loyalty-section__wrapper {
  .loyalty-member-welcome {
    .logged-in {
      .logged-in-user-heading {
        color: color('loyalty');
        font-size: 1.5rem;
      }
    }
  }
}

.bag-mfe-V2 {
  .loyalty-section__wrapper {
    .loyalty-member-welcome {
      .logged-in {
  
        .icon-block-icon {
          margin-left: auto;
        }
  
        .shopping-bag-text__info {
          display: flex;
          align-items: center;
        }
  
        .logged-in-pipeline {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
  #rewards-coupons-list-panel-id .coupon-list .discount-card {
    margin: 0px 0px 2rem 0px;
  }
}