
/* due to Google requirements this icon had to 
be rectangular and doesn't fit in our standard icons
so we need to override DS styles a little bit */

.payment-manager-header-section {
  .heading-secure {
    background: #f6f6f6;
    font-family: inherit;
    min-height: 6rem;
    padding: 1.15rem 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    .icon {
      font-size: 2rem;
      margin-left: 1rem;
    }
  }
}

.payment-options {
  margin-top: 3rem;

  .payment-tile-group {
    padding: 0;
  }

  .payment-options-header {
    display: flex;
    justify-content: space-between;
  }

  .payment-option {
    .payment-icon-wrap {
      .payment-icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .radio-button-interior-label {
      padding-right: 2rem;
      .secondary {
        display: block;
      }
    }
  }

  .payment-type-description {
    margin: 2rem 0;

    .store-images {
      align-items: baseline;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  .payment-option-fields-wrap {
    padding-bottom: 1.5rem;
    padding-left: 4rem;
    width: 100%;

    &.tile-view {
      padding-bottom: 0;
      padding-left: 0;
    }

    .credit-card-fields-wrap {
      padding-bottom: 1.5rem;
    }
    .klarna-fields-wrap {
      margin-top: 1.5rem;
    }

    .payment-type-description {
      margin: 0;
    }
  }

  .button {
    &.ds-override {
      color: inherit;
      font-weight: initial;
      min-height: auto;
      padding: 0;
      text-decoration: underline;
    }
    &.learn-more-button {
      margin-left: .5rem;
    }
  }

  .credit-card-fields-wrap {
    .save-card-for-future {
      margin: 2rem 0 1rem;
    }
  }

  .selected-saved-payment {
    .selected-saved-payment-card {
      border-bottom: 1px solid border-color('container');
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      padding: 2rem 0;
    }

    .selected-saved-payment-card-security {
      margin-bottom: 2rem;
      
      label {
        font-weight: 700;
      }

      .input-field {
        margin-top: 2rem;
      }
    }

    .selected-saved-payment-card-action {
      .button {
        min-width: 14rem;
      }
    }
  }
}

.cart-payment-details {
  padding-bottom: 3rem;

  .payment-name {
    padding-bottom: 1rem;
  }
}
  
#saved-payments-modal {
  .saved-payment-card {
    background-color: color('secondary-light');
    margin-bottom: 3rem;
    padding: 3rem;

    &:last-child {
      margin-bottom: 0;
    }

    .card-details {
      margin-bottom: 2rem;
    }
  }
}