.paypal-express-button {
  border: 1px solid color('neutral-900');
  box-sizing: border-box;
  display: block;
  height: 50px;
  margin: 2rem 0;
  width: 100%;
  background-color: color('neutral-000');
  background-repeat: no-repeat;

  img {
    display: block;
    height: rem(20);
    width: rem(75);
    margin: auto auto 0 auto;
  }
}
