.order-total-content {
  padding: 10px 0px 0px;
  .tax-subtext {
    padding-top: 1.5rem;
  }
  
  .transaction-summary 
  {
    width: 100%;
    .transaction-summary-footer{
      th {
        font-weight: bold;
      }
    }
    .button[data-variant=borderless]{
      font-weight: 400;
      color: color('body-text-color');
      padding-left: 0;
      min-height: auto;
    }
    .gift-card-content {
      display: inline;
    }
    .vat-details-button {
      text-transform: capitalize;
      text-decoration: underline;
    }
  }
  .links {
    margin-top: 2rem;
  }
  .customer-service {
    margin-bottom: 3rem;
    margin-top: 5rem;
    .customer-service-phone-number-block, .order-number-block {
      margin-top: 20px;
      .or {
        display: inline;
        margin: 0px 3px;
        text-transform: lowercase;
      }
    }
  }
  
  ul {
    list-style: none;
  }

}
