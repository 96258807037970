.loader {
  height: 25px;
  margin: 25px auto;
  max-width: 25px;
}

.hazy-loader[data-state=true] {
  opacity: 0.4;
  pointer-events: none;
}

.shimmer-loader {
  background-color: color('primary-light');
  display: flex;
  padding: 2rem;
  @include media-query(1025) {
    gap: 1.5rem 15%;
    padding: 10rem 10%;
  }
  .left-rail {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0;
    padding: 0;
    width: 100%;
    @include media-query(1025) {
      width: 55%;
    }
    .first-row {
      animation: shimmer-loader 1s infinite ease-out;
      height: 4rem;
      width: 100%;
    }
    .second-row {
      animation: shimmer-loader 1s infinite ease-out;
      height: 10rem;
      margin-bottom: 2rem;
      width: 100%;
      @include media-query(1025) {
        margin-bottom: 4rem;
      }
    }
    .left-rail-row {
      display: flex;
      gap: 1.5rem;
      height: 15rem;
      .image {
        animation: shimmer-loader 1s infinite ease-out;
        height: 100%;
        width: 30%;
      }
      .content {
        animation: shimmer-loader 1s infinite ease-out;
        height: 100%;
        width: 70%;
      }
    }
  }
  .payment-rail {
    height: 0;
    margin: 0;
    padding: 0;
    width: 0;
    @include media-query(1025) {
      animation: shimmer-loader 1s infinite ease-out;
      height: auto;
      width: 30%;
    }
  }
}


@keyframes shimmer-loader {
  0% {
    background-color: color('secondary-light');
  }

  50% {
    background-color: color('primary-light');
  }

  100% {
    background-color: color('secondary-light');
  }
}