.submit-legal-terms-wrapper {
   @include media-query(540) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }

  @include media-query(700) {
    padding-bottom: 5rem;
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
}

.mfe-order-submit-legal {
  margin-bottom: -3rem;
  @include media-query(0, 540) {
    margin-bottom: -4rem;
  }
  @include media-query(540) {
    margin-bottom: -4rem;
  }
  @include media-query(700) {
    margin-bottom: -6rem;
  }

  .more-information-wrapper {
    margin-bottom: 3rem;
  }
  div > div {
    margin-top: 2rem;
  }
  .privacy-notice {
    margin-bottom: 2rem;
  }
  hr {
    margin-left: 0;
    margin-right: 0;
  } 
  .pipe-delimited-links.legal-links {
    padding: 0;
  }
  .order-submit-checkbox-wrapper .checkbox {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 4rem;

    button{
      font-weight: bold;
      margin-top:0;
      margin-bottom: 0;
    }
  }
  [aria-invalid="true"] + label button {
    color: #cc0000;
  }
  button.button.ds-override.button-as-link.modal-link.js-legal-policy-modal, .returns-button, .more-info-button {
    font-size: 1.3rem;
  }
  .checkbox-label.import-duty-waiver {
    margin-top: 5rem;
  }
  .legal-submit-order-button-copy {
    padding-bottom: 0;
    margin-top: 2rem;
  }
}
