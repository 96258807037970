.loyalty-rewards-section {
  .rewards-promos {
    .repudiation {
      align-items: center;
      display: flex;
      padding: 2rem;
    }
    button.button.show-tmnt-modal-btn {
      height: auto;
      min-width: auto;
    }
    .discount-card[data-orientation='stacked'] .discount-actions .discount-applied {
      display: inline-flex;
    }
  }
  .user-notification {
    font-family: inherit;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 5px 0px;
    color: color('primary-dark');
  }
}

#rewards-coupons-list {
  background-color: color('secondary-light');
}

#rewards-coupons-list-panel-id {
  background-color: color('secondary-light');
  .estimated-points-section p {
    display: inline;
  }
  .coupon-list {
    .discount-card {
      margin: 2rem 0; 
      .discount-content {
        background: url(https://img.abercrombie.com/is/image/anf/background-ticket.svg);
        display: block;
        .discount-actions {
          margin: 15px 0 0 0;
          .redeem-button {
            width: 100%;
          }
        }
      }
  }
  }
}

.bag-mfe-V2{
  .h3.rewards-status {
    margin-bottom:10px;
  }

  #rewards-coupons-list-panel-id {
    .coupon-list {
      .discount-card {
        .discount-content {
          background: #ffffff;
          display: flex;
        }

        .discount-actions {
          margin: 0px 0px 0px auto;
        }
      }
    }
  }
  .remove-button-v2{
    justify-content: flex-end;
    margin-left: 15px;
  }
}

.bag-mfe-V2-right {

  #rewards-coupons-list-panel-id .coupon-list .discount-card {
    margin: 0px 0px 2rem 0px;
  }

  #rewards-coupons-list-panel-id .coupon-list .discount-card .discount-content {
    display: flex;
  }

  #rewards-coupons-list, #rewards-coupons-list-panel-id {
    background-color: transparent;
  }

  #rewards-coupons-list-panel-id .coupon-list .discount-card .discount-content .discount-actions {
    margin: 0px 0px 0px auto;
  }

  #rewards-coupons-list-panel-id .coupon-list .discount-card .discount-content {
    background: #ffffff;
  }

  .remove-button-v2 {
    justify-content: flex-end;
    margin-left: 15px;
  }

  .h3.rewards-status {
    margin-bottom: 10px;
  }

  .icon-block-text.currency_until {
    margin-bottom: 20px;
    display: inline-block;
  }

  div#right-rail-reward-offer + .checkoutpage-promo {
    padding-top: 0px;
  }

}