.charity__wrapper {
    padding: 0 rem(75) 0 rem(75);

    .charity-tiles__section {
        flex-direction: column;
        margin: 0 auto;
    }

    .checkbox-text,
    .charity-tiles__section {
        display: flex;
    }

    .charity-tiles {
        position: relative;
    }
}