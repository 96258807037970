.order-review {
  max-width: 510px;
  margin: 2.5rem 7.5rem 3.75rem 7.5rem;
  padding: 2.5rem;
  border: 1px solid color('$secondary-light');
  display: flex;
  flex-direction: column;

  >section:not(:first-of-type):not(.shipping-methods-guest) {
    border-top: 1px solid color('$secondary-light');
    margin-top: 2rem;
    padding-top: 2rem;
  }

  .order-review-heading {
    text-align: center;
    text-transform: capitalize;
    padding: 2rem 0;
  }

  .estimated-delivery-date {
    margin-top: 2.5rem;
  }
}