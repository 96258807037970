.ocn-create-account {
  width: 100%;
  
  &-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  
    &__email {
      margin: 1rem 0;
    }
  
    &__password-hint {
      margin: 0;
    }
  }
  #blank-space {
    padding-left: 1rem;
  }
}
