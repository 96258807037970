.credit-card-fields .tooltip-widget {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  .tooltip-content {
    img {
      max-width: 22rem;
    }
  }
}