.cod-track-your-order {
  background-color: color('primary-light');
  margin: 4rem 1rem 0 1rem;
  padding: 3rem 2rem;
  text-align: left;
  @include media-query(700) {
    width: 600px;
    margin: 4rem auto 0 auto;

  }
  @include media-query(1025) {
    border: 0.1rem solid color('tertiary-light');
    margin-left: 3.5rem;
    margin-right: 3.5rem;
    max-width: 60rem;
    padding: 0;
    text-align: left;
  }
  .track-your-order-heading {
    background-color: initial;
    padding: 0;
    margin: 0 1rem 2rem 1rem;
    text-align: center;
    @include media-query(700) {
      margin-bottom: 3rem;
    }
    @include media-query(1025) {
        background-color: color('secondary-light');
        margin-top: initial;
        margin-bottom: 3rem;
        margin-left: 0;
        margin-right:0;
        max-width: 60rem;
        padding: 3rem 4rem 4rem 4rem;
    }
  }
  .order-tracking-details {
    @include media-query(1025) {
      margin-top: 4rem;
      max-width: 60rem;
      padding: 0 4rem 4rem;
      text-align: left;
    }
    .track-order {
      display: flex;
      padding-bottom: 3rem;
      .track-order-explained {
        margin-left: 0.5rem;
      }
    }
    .order-delivery {
      display: flex;
      margin: 3rem auto;
      align-items: center;
      @include media-query(1025) {
        margin: 2rem auto;
      }
      p {
        margin-left: 0.5rem;
      }
    }
    .step-circle {
      border-color: color('primary-dark');
      border-radius: 50%;
      border-style: solid;
      border-width: 1px;
      color: color('primary-dark');
      height: 4rem;
      padding-bottom: 1px;
      padding-left: 2px;
      margin-right: 1rem;
      width: 4.75rem;
      @include media-query(700) {
        width: 4rem;
      }
      @include media-query(1025) {
        width: 4rem;
      }
      &[data-state='active'] {
        background-color: color('primary-dark');
        color: color('primary-light');
        width: 9rem;
        @include media-query(700) {
          width: 5rem;
        }
        @include media-query(1025) {
          width: 5rem;
        }
      }
      .step-inner {
        align-items: center;
        display: flex;
        justify-content: center;
        line-height: 1.4615384615;
        margin-top: 1rem;
      }
      .step-inner::after {
        content: attr(data-text);
        font-weight: bold; 
      }
    }
    #returns-panel-id p:nth-child(2) {
      margin: 1rem 0;
    }
  }
}