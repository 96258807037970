.sold-out-inventory-repudiation-section {
  background-color: color('secondary-light');
  margin: 3rem auto 0 auto;
  padding: 3rem 2rem;
  width: 100%;
  .sold-out-wrap {
    border-bottom: 2px solid text-color('discount-light');
    margin-bottom: 2rem;
    margin-top: 3rem;
    .product-template {
      border-bottom: 1px solid text-color('body-inverse');
      display: flex;
      grid-column-gap: 1.5rem;
      padding: 1.9rem 0;
      &:last-child {
        border-bottom: 0;
      }
      .product-image-section {
        flex: 0 1 auto;
      }
      .product-name {
        color: color('primary-dark');
        font-size: 1.6rem;
        line-height: 1.125;
        text-transform: none;
      }
      .product-sku {
        font-size: 1.1rem;
        margin-top: .5rem;
      }
      .product-size-color {
        font-size: 1.3rem;
        margin-top: 1.5rem;
      }
      .product-price-text-wrapper {
        margin: 0.5rem 0 2rem 0;
        .product-price-text {
          color: text-color('heading');
          display: inline-block;
          font-size: 1.3rem;
          font-weight: 400;
          line-height: 1.5384615385;
          margin-right: .7rem;
          &.with-discount {
            text-decoration: line-through;
          }
          &.discount {
            color: text-color('discount');
            font-weight: 400;
          }
        }
      }
    }
  }
}